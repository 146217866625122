/* eslint-disable import/no-unresolved */
import Flatpickr from "stimulus-flatpickr"
import { Dropdown, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
import NestedForm from "stimulus-rails-nested-form"
import CheckboxSelectAll from "stimulus-checkbox-select-all"
import Autosave from "stimulus-rails-autosave"

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import controllers from "./**/*_controller.js"

import application from "./application"

// Register each controller with Stimulus

controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

application.register("dropdown", Dropdown)
// application.register('modal', Modal);
application.register("tabs", Tabs)
application.register("popover", Popover)
application.register("toggle", Toggle)
application.register("slideover", Slideover)
application.register("flatpickr", Flatpickr)
application.register("nested-form", NestedForm)
application.register("checkbox-select-all", CheckboxSelectAll)
application.register("autosave", Autosave)
