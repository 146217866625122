// https://github.com/stimulus-components/stimulus-textarea-autogrow

import { Controller } from "@hotwired/stimulus"
import debounce from "./utils/debounce"

export default class extends Controller {
  static values = {
    resizeDebounceDelay: {
      type: Number,
      default: 100
    }
  }

  initialize() {
    this.autoGrow = this.autoGrow.bind(this)
    this.reset = this.reset.bind(this)
  }

  connect() {
    this.element.style.overflow = "hidden"
    const delay = this.resizeDebounceDelayValue

    this.onResize = delay > 0 ? debounce(this.autoGrow, delay) : this.autoGrow

    this.element.addEventListener("focusin", this.autoGrow)
    this.element.addEventListener("focusout", this.reset)
    this.element.addEventListener("input", this.autoGrow)
  }

  disconnect() {
    this.element.removeEventListener("focusin", this.autoGrow)
    this.element.removeEventListener("focusout", this.reset)
    this.element.removeEventListener("input", this.autoGrow)
  }

  autoGrow() {
    this.originalWidth = this.element.parentElement.scrollWidth
    this.element.style.width = `${this.originalWidth}px`

    // this.element.style.width = this.originalWidth;
    this.element.style.height = "auto" // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${this.element.scrollHeight}px`
  }

  reset() {
    this.element.style.height = ""
    this.element.style.width = ""
  }
}
