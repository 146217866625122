/* eslint-disable class-methods-use-this */
// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  goBack(event) {
    if (this.previousPath.length > 0) {
      event.preventDefault()
      Turbo.visit(this.previousPath)
      this.resetPreviousPath()
    }
  }

  resetPreviousPath() {
    localStorage.setItem("previousPath", "")
  }

  get previousPath() {
    return localStorage.getItem("previousPath")
  }
}
