import CheckboxSelectAll from "stimulus-checkbox-select-all"
import { patch, destroy } from "@rails/request.js"

export default class extends CheckboxSelectAll {
  static targets = ["selectedCount", "selectField", "textField", "submitButtons", "parentSelectAllCheckbox"]

  static values = {
    engagementId: String
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.addEventListener("change", this.toggle)
    this.refresh()
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.removeEventListener("change", this.toggle)
  }

  refresh(event) {
    if (event) {
      event.preventDefault()
    }
    super.refresh()

    this.selectedCountTarget.textContent = this.checked.length

    this.maybeEnableSubmitButton()
  }

  toggle(event) {
    super.toggle(event)

    this.selectedCountTarget.textContent = this.checked.length

    this.maybeEnableSubmitButton()
  }

  bulkScopeOut(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/controls/bulk_scope_out`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control: { ids: this.checkedIds } })
    })

    this.uncheckAll()
  }

  bulkScopeIn(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/controls/bulk_scope_in`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control: { ids: this.checkedIds } })
    })

    this.uncheckAll()
  }

  bulkDestroy(event) {
    event.preventDefault()

    destroy(`/engagements/${this.engagementIdValue}/controls/bulk_destroy`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control: { ids: this.checkedIds } })
    })

    this.uncheckAll()
  }

  // Unselects all checkboxes
  unselectAll(event) {
    event.preventDefault()

    this.uncheckAll()
  }

  // eslint-disable-next-line class-methods-use-this
  checkAllNested(event) {
    if (event) {
      event.preventDefault()
      const parentElement = event.target.parentElement.closest(".collapse-component")
      if (!parentElement) return

      const checkboxes = parentElement.querySelectorAll('input[data-control-bulk-actions-target="checkbox"]')
      if (!checkboxes) return

      checkboxes.forEach((checkbox) => {
        // eslint-disable-next-line no-param-reassign
        checkbox.checked = event.target.checked
        this.triggerInputEvent(checkbox)
      })

      this.refresh()
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkAllParents(event) {
    if (event) {
      event.preventDefault()
      if (!this.parentSelectAllCheckboxTargets) return

      this.parentSelectAllCheckboxTargets.forEach((checkbox) => {
        // eslint-disable-next-line no-param-reassign
        checkbox.checked = event.target.checked
        this.triggerInputEvent(checkbox)
      })

      this.refresh()
    }
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = false

      this.triggerInputEvent(checkbox)
    })

    this.resetSelectedCount()
    this.resetSelectFieldTarget()
    this.maybeEnableSubmitButton()
  }

  bulkChangeConclusions(event) {
    event.preventDefault()

    const form = this.element.querySelector("form.simple_form")
    const formData = new FormData(form)

    patch(`/engagements/${this.engagementIdValue}/engagement_criteria/bulk_change_conclusions`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        engagement_criteria: {
          ids: this.checkedIds,
          designed_properly: formData.get("engagement_criteria[designed_properly]"),
          designed_properly_rationale: formData.get("engagement_criteria[designed_properly_rationale]"),
          operating_effectively: formData.get("engagement_criteria[operating_effectively]"),
          operating_effectively_rationale: formData.get("engagement_criteria[operating_effectively_rationale]")
        }
      })
    })

    this.uncheckAll()
    form.reset()
  }

  bulkChangeRisks(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/controls/bulk_change_risks`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        control: { ids: this.checkedIds },
        risk_rationale: this.textFieldTarget.value,
        risk_level: this.selectFieldTarget.value
      })
    })

    this.uncheckAll()
    this.selectFieldTarget.value = "1"
    this.textFieldTarget.value =
      "The audit team assesses control risk at moderate unless determined otherwise during the planning and risk assessment process. Changes in risk determination are to be documented here."
  }

  bulkMapToTopic(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/control_topics/bulk_map_to_topic`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control: { ids: this.checkedIds }, topic: { id: this.selectFieldTarget.value } })
    })

    this.uncheckAll()
  }

  bulkRemapToTopic(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/control_topics/bulk_remap_to_topic`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control_topic: { ids: this.checkedIds }, topic: { id: this.selectFieldTarget.value } })
    })

    this.uncheckAll()
  }

  mapTopicToControl(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/control_topics/map_topic_to_control`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control: { ids: this.checkedIds }, topic: { id: this.selectFieldTarget.value } })
    })

    this.resetSelectFieldTarget()
  }

  bulkDestroyMapToTopic(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/control_topics/bulk_destroy_map_to_topic`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control_topic: { ids: this.checkedIds } })
    })

    this.uncheckAll()
  }

  bulkMoveDocumentsToGroup(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/documents/update_group`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        document: { ids: this.checkedIds },
        document_group: { id: this.selectFieldTarget.value }
      })
    })

    this.uncheckAll()
  }

  bulkDestroyMapToCriteria(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/control_criteria/bulk_destroy_map_to_criteria`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ control_criteria: { ids: this.checkedIds } })
    })

    this.uncheckAll()
  }

  bulkAssignTeamMember(event) {
    event.preventDefault()

    patch(`/engagements/${this.engagementIdValue}/topics/bulk_update_team_member`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        topic: { ids: this.checkedIds },
        team_member: { id: this.selectFieldTarget.value }
      })
    })

    this.uncheckAll()
  }

  resetSelectedCount() {
    this.selectedCountTarget.textContent = 0
    this.updateSubmitButtonsTargets(false)

    if (this.hasCheckboxAllTarget) {
      this.checkboxAllTarget.checked = false
      this.checkboxAllTarget.indeterminate = false
    }
  }

  resetSelectFieldTarget() {
    if (this.hasSelectFieldTarget) {
      this.selectFieldTarget.value = ""
    }
  }

  maybeEnableSubmitButton() {
    this.updateSubmitButtonsTargets(this.checked.length === 0)
  }

  updateSubmitButtonsTargets(value) {
    this.submitButtonsTargets.forEach((target) => {
      // eslint-disable-next-line no-param-reassign
      const selectField = target.previousElementSibling

      if (selectField && (selectField.nodeName === "SELECT" || selectField.nodeName === "INPUT")) {
        // eslint-disable-next-line no-param-reassign
        target.disabled = selectField.value === "" || this.checked.length === 0
      } else {
        // eslint-disable-next-line no-param-reassign
        target.disabled = value
      }
    })
  }

  get checkedIds() {
    return this.checked.map((checkbox) => checkbox.value)
  }
}
