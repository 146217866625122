import Alpine from "alpinejs"

function impactLevel(initialImpactLevel) {
  return {
    impactLevel: initialImpactLevel,

    init() {
      this.handleImpactLevelChange(this.impactLevel)
    },

    handleImpactLevelChange(value) {
      const isNonOccurrence =
        value === "5-Non-occurrence, no impact on opinion" || value === "6-Non-occurrence, modified opinion"

      if (isNonOccurrence) {
        this.$refs.priority.value = "N/A"
        this.$refs.priority.classList.add("pointer-events-none", "opacity-50")
      } else {
        this.$refs.priority.classList.remove("pointer-events-none", "opacity-50")
      }
    }
  }
}

Alpine.data("impactLevel", impactLevel)
