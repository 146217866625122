import { Controller } from "@hotwired/stimulus"
import Alpine from "alpinejs"

export default class extends Controller {
  connect() {
    this.modalStore = Alpine.store("modal")

    this.modalTurboFrame.removeAttribute("src")

    this.forms.forEach((form) => {
      if (form.id !== "preparer_sign_off" && form.id !== "primary_reviewer_sign_off") {
        form.addEventListener("turbo:submit-end", this.handleFormSubmit.bind(this))
      }
    })

    document.addEventListener("turbo:before-cache", this.destroyModal.bind(this))
  }

  disconnect() {
    this.forms.forEach((form) => {
      form.removeEventListener("turbo:submit-end", this.handleFormSubmit.bind(this))
    })

    document.removeEventListener("turbo:before-cache", this.destroyModal.bind(this))
  }

  handleFormSubmit(event) {
    if (event.detail.success) {
      this.modalStore.close()
    }
  }

  destroyModal() {
    this.modalStore.close()
    this.element.remove()
  }

  // eslint-disable-next-line class-methods-use-this
  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']")
  }

  get forms() {
    return this.element.querySelectorAll("form")
  }
}
