import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  selectRadioOption(event) {
    this.inputTargets.forEach((el, _i) => {
      if (event.target !== el) {
        // eslint-disable-next-line no-param-reassign
        el.checked = false
      }
    })
  }
}
