import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static values = {
    settings: Object
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,

      settings: {
        // hideSelected: true,
        // maxValuesShown: 5, // Default 20

        ...this.settingsValue
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}
