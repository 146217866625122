import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: ".drag-handle",
      group: this.element.id,
      ghostClass: "opacity-0",
      onEnd: this.onEnd.bind(this)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  onEnd(event) {
    const { itemModel, itemUrl } = event.item.dataset
    const position = event.newIndex + 1

    patch(itemUrl, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ [itemModel]: { position } })
    })
  }
}
