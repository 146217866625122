import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="show-hide"
export default class extends Controller {
  static targets = ["input", "output", "evidenceAlert"]

  static classes = ["hide"]

  static values = { showIf: [String] }

  connect() {
    this.toggle()
  }

  toggle() {
    if (
      this.showIfValue.includes(this.inputTarget.value) ||
      (this.inputTarget.type === "checkbox" && this.showIfValue.includes(this.inputTarget.checked))
    ) {
      this.outputTarget.classList.remove(this.hideClass)
    } else {
      this.outputTarget.classList.add(this.hideClass)
    }
  }

  handleDocumentTypeChange() {
    if (this.showIfValue.includes(this.inputTarget.value)) {
      this.evidenceAlertTarget.classList.add(this.hideClass)
    } else {
      this.evidenceAlertTarget.classList.remove(this.hideClass)
    }
  }
}
