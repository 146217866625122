// Custom TailwindCSS modals for confirm dialogs
//
// Example usage:
//
//   <%= button_to "Delete", my_path, method: :delete, form: {
//     data: {
//       turbo_confirm: "Are you sure?",
//       turbo_confirm_description: "This will delete your record. Enter the record name to confirm.",
//       turbo_confirm_text: "record name"
//     }
//   } %>

function insertConfirmModal(message, element, button) {
  let confirmInput = ""

  // button is nil if using link_to with data-turbo-confirm
  const confirmText = button?.dataset?.turboConfirmText || element.dataset.turboConfirmText
  const description = button?.dataset?.turboConfirmDescription || element.dataset.turboConfirmDescription || ""
  if (confirmText) {
    confirmInput = `<input type="text" class="form-input" data-behavior="confirm-text" />`
  }

  const content = `
    <div id="confirm-modal" class="z-[999] animated fadeIn fixed top-0 left-0 w-full h-full table" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">

        <div class="flex flex-col max-w-sm p-8 mx-auto bg-white rounded shadow gap-4">
          <p class="h6 mb-3">${message}</p>
          ${description}

          ${confirmInput}

          <div class="flex flex-wrap items-center justify-end mt-4 gap-4">
            <button data-behavior="cancel" class="btn btn-light-gray">Cancel</button>
            <button data-behavior="commit" class="btn btn-danger focus:outline-none">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `

  document.body.insertAdjacentHTML("beforeend", content)
  document.activeElement.blur()
  const modal = document.getElementById("confirm-modal")

  // Focus on the first button in the modal after rendering
  modal.querySelector("button").focus()

  // Disable commit button until the value matches confirmText
  if (confirmText) {
    const commitButton = modal.querySelector("[data-behavior='commit']")
    commitButton.disabled = true
    modal.querySelector("input[data-behavior='confirm-text']").addEventListener("input", (event) => {
      commitButton.disabled = event.target.value !== confirmText
    })
  }

  return modal
}

window.Turbo.setConfirmMethod((message, element, button) => {
  const dialog = insertConfirmModal(message, element, button)

  return new Promise((resolve, _reject) => {
    dialog.querySelector("[data-behavior='cancel']").addEventListener(
      "click",
      (_event) => {
        dialog.remove()
        resolve(false)
      },
      { once: true }
    )
    dialog.querySelector("[data-behavior='commit']").addEventListener(
      "click",
      (_event) => {
        dialog.remove()
        resolve(true)
      },
      { once: true }
    )
  })
})
