/* eslint-disable no-console */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("reload frame controller connected")
  }

  disconnect() {
    console.log("reload frame controller disconnect")
  }
}
