// Rails functionality
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import Rails from "@rails/ujs"
import * as LocalTime from "local-time"
import "@rails/actiontext"
import Alpine from "alpinejs"
import flatpickr from "flatpickr"
import collapse from "@alpinejs/collapse"
import "cocoon-js-vanilla"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
// eslint-disable-next-line import/no-unresolved
import "./src/**/*"

// Make accessible for Electron and Mobile adapters
// @ts-ignore
window.Alpine = Alpine
window.Rails = Rails
window.Turbo = Turbo

ActiveStorage.start()
LocalTime.start()
Rails.start()

document.addEventListener("alpine:init", () => {
  Alpine.store("modal", {
    show: false,

    toggle() {
      this.show = !this.show
    },

    close() {
      this.show = false
    },

    open() {
      this.show = true
    }
  })
})

Alpine.plugin(collapse)
Alpine.start()

document.addEventListener("DOMContentLoaded", () => {
  flatpickr(".datepicker", {
    mode: "range",
    static: true,
    monthSelectorType: "static",
    dateFormat: "M j, Y",
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (_selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace("to", "-")
      const customClass = instance.element.getAttribute("data-class") || ""
      instance.calendarContainer.classList.add(customClass)
    },
    onChange: (_selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace("to", "-")
    }
  })
})
