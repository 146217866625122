/* import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ['checkbox']

  connect() {
    super.connect()
    console.log('Do what you want here.')

    // Get all checked checkboxes
    console.log("start unchecked", this.unchecked)
    console.log("start checked", this.checked)

    // Get all unchecked checkboxes
    //this.unchecked
  }

  document({ detail: { message } }) {
    console.log('documents')
    console.log('checked', this.checked)
    // Do something
  }
} */
