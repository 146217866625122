// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateField"]

  static values = {
    content: String
  }

  insertIntoTarget() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.setDate(this.contentValue, true)
    } else {
      this.dateFieldTarget.value = this.contentValue
    }
  }

  clearTarget() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.setDate("", true)
    } else {
      this.dateFieldTarget.value = ""
    }
  }

  get flatpickrInstance() {
    // eslint-disable-next-line no-underscore-dangle
    return this.dateFieldTarget._flatpickr
  }
}
